<template>
  <div class="PerformStep">
    <div class="content">
      <div class="introduce">
        <img
          class="introduce-pic"
          src="../../assets/image/农民工工资保函.png"
          alt=""
        >
        <div class="introduce-text">
          <div class="text-tit">
            农民工工资保函
          </div>
          <div class="text-detail">
            农民工工资支付担保是指担保人对业主或承包商支付民工工资义务的担保。一旦业主或承包商违约，不能按时、足额支付民工工资，担保人将代为支付(之后担保人再向业主或承包商追偿)，以保障(农)民工的合法权益.
          </div>
          <a-button
            class="text-btn"
            type="primary"
            @click="$router.push({ path: '/performance', query: { type: '2' } })"
          >
            立即申请
          </a-button>
        </div>
      </div>
      <div class="describe">
        <div class="describe-list">
          <div class="list-tit">
            一、产品定义：
          </div>
          <div class="list-text">
            总承包单位、业主等企业的申请开立，按照《保障农民工工资支付条例》要求可用以替代工
            资保证金的保函。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            二、担保方式承包商履约担保可采用的方式：
          </div>
          <div class="list-text">
            ①专业担保公司的保函
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            三、担保额度:
          </div>
          <div class="list-text">
            根据工程价款比例缴存，一般为工程总价2%-5%。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            四、担保期限:
          </div>
          <div class="list-text">
            农民工工资担保的有效期应当在保证合同中约定，一般不超过3年。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            五、客户申请提交资料清单：
          </div>
          <div class="list-text">
            1.企业基本资料：营业执照、财报报告、法人代表身份证等；2.工程资料：招标文件，中标
            通知书保函格式施工合同、项目要求的其他资料
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>
<style scoped>
.content{
  width: 1500px;
  margin: 0 auto;
  padding: 30px 140px;
}
.introduce{
    display: flex;
    margin-bottom: 50px;
}
.introduce-text{
    margin-left: 30px;
}
.text-tit{
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 5px;
}
.text-detail{
    line-height: 32px;
    margin-left: 5px;
}
.describe-list{
    margin-bottom: 50px;
}
.list-tit{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.list-text{
     font-size: 14px;
     line-height: 30px;
}
</style>
